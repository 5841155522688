/* You can add global styles to this file, and also import other style files */
@use '@angular/material' as mat;
@import '@angular/material/prebuilt-themes/indigo-pink.css';
@import 'material-icons.scss';
@import 'inter.scss';

$my-typography: mat.define-typography-config(
  $font-family: "'Inter'",
);

:root {
	@include mat.form-field-density(-2);
  @include mat.all-component-typographies($my-typography);
}

html {
	height: 100dvh;
  --mat-select-arrow-transform: unset;
  font-family: 'Inter', serif !important;
}

h4, mat-form-field, button {
  font-family: 'Inter', sans-serif !important;
}

@media (min-width: 500px) {

	html {
		background: url(assets/images/bg_standard.jpg) no-repeat center center fixed;
	  -webkit-background-size: cover;
	  -moz-background-size: cover;
	  -o-background-size: cover;
	  background-size: cover;

		-webkit-transition: background 300ms ease-in 2s; /* property duration timing-function delay */
    -moz-transition: background 300ms ease-in 2s;
    -o-transition: background 300ms ease-in 2s;
    transition: background 300ms ease-in 2s;


    -webkit-transition-property: background;
    -webkit-transition-duration: 300ms;
    -webkit-transition-timing-function: ease-in;
    -webkit-transition-delay: 100ms;

    -moz-transition-property: background;
    -moz-transition-duration: 300ms;
    -moz-transition-timing-function: ease-in;
    -moz-transition-delay: 100ms;

    -o-transition-property: background;
    -o-transition-duration: 300ms;
    -o-transition-timing-function: ease-in;
    -o-transition-delay: 100ms;

    transition-property: background;
    transition-duration: 300ms;
    transition-timing-function: ease-in;
    transition-delay: 100ms;
	}

}

body {
	margin: 0;
}

.login-form {
	display: flex;
  flex-direction: column;
}

.login-form h4 {
	margin: 20px 0;
}

h4 {
  font-weight: 600;
  font-size: 15px;
  line-height: 21px;
}

.sub-heading {
  font-size: 15px;
  font-weight: 400;
  margin-top: 0;
}

.form-buttons {
	margin-top: 20px;
	display: block;
}

.alert.error div, .alert.info div {
  padding: 12px 16px 12px 16px;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  margin-bottom: 16px;
}

.alert.error div {
  background: #FFECEA;
  color: #AD2D1C;
}

.alert.info div {
  background: rgba(47,128,237,.1);
  color: #222;
}

@media (min-width: 601px) {
  .mat-mdc-card {
    --mdc-outlined-card-container-elevation: 0 10px 13px -6px rgba(0, 0, 0, .2), 0 20px 31px 3px rgba(0, 0, 0, .14), 0 8px 38px 7px rgba(0, 0, 0, .12);
  }
}

@media (max-width: 600px) {
  .cardContainer {
    height: 100dvh;
  }

  .mat-mdc-card {
    --mdc-outlined-card-container-elevation: 0;
  }

  .mat-mdc-card .cardContainer {
    justify-content: space-between !important;
    align-items: stretch !important;
  }

  .cardContainer img {
    margin-bottom: 0 !important;
  }

  .mat-mdc-raised-button, .mat-mdc-button {
    width: 100%;
    margin-top: 12px !important;
    margin-bottom: 12px !important;
  }

  .password-field {
    margin-bottom: 0;
  }

  input:focus, mat-select:focus {
    font-size: 16px;
  }

  .router-outlet-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: stretch;
  }

  app-login, app-login form,
  app-password, app-password form,
  app-account, app-account form,
  app-activate-account, app-activate-account form,
  .login-form {
    height: 100%;
  }

  .login-form {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: stretch;
  }
}

.mat-icon {
	color: #666;
}

mat-form-field {
	display: block !important;
}

.mat-mdc-text-field-wrapper {
  padding-left: 8px !important;
}

.mat-mdc-form-field-hint-wrapper {
  padding-left: 0 !important;
}

.region-selection {
  max-width: 40%;
  margin-left: auto;
  margin-top: 5px;
  text-align: center;
  padding-top: 2px;
  font-size: 15px;
}

.mat-mdc-raised-button {
  font-weight: 500;
  font-style: normal;
  box-shadow: unset !important;
}

.mat-mdc-button {
  font-weight: 600;
  color: #1069E4 !important;
  --mat-text-button-state-layer-color: rgb(0, 0, 0, 0);
  --mat-text-button-ripple-color: rgb(0, 0, 0, 0);
}

.mat-mdc-button .mat-button-focus-overlay,
.mat-button .mat-button-ripple {
  display: none;
}

.form-buttons {
  .mat-mdc-raised-button, .mat-mdc-button {
    font-size: 15px;
    height: 46px;
    box-shadow: unset !important;
    white-space: nowrap;
  }

  .mat-mdc-raised-button:disabled {
    background-color: rgba(0,0,0,.12) !important;
    color: rgba(0,0,0,.26) !important;
  }
}

mat-form-field {
  font-size: 15px !important;
  font-weight: 400 !important;
  line-height: 22px !important;
  letter-spacing: 0;
  text-align: left;
  color: #262626;
  --mdc-filled-text-field-container-shape: 0;
  --mdc-filled-text-field-disabled-container-color: rgba(0, 0, 0, 0.1);
  --mat-form-field-state-layer-color: rgba(0, 0, 0, 0);
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-background-clip: text;
}

mat-form-field .mat-mdc-form-field-hint {
  font-size: 13px;
  line-height: 19px;
  font-weight: 400;
}

.field-wrapper {
  margin-bottom: 24px;
}

/*!* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. *!
mat-form-field .mat-form-field-underline {
  background-color: #323232;
}*/

p {
  font-size: 15px;
  font-weight: 400;
}

.mat-mdc-form-field-icon-suffix {
  padding-right: 8px;
}
